import React, { useEffect } from "react"
import SEO from "../../../components/Seo/Seo"
import { blogUrlMapper } from "./blogUrlMapper"
import { navigate } from "gatsby"

const blogMetadata = {
  Title: "Flipbase Blog - Klantverhalen, Trends en meer",
  Keywords: ["Flipbase blog", "Flipbase klantverhalen"],
  Description:
    "Meer weten over Recruitment en Flipbase? Lees onze klantverhalen en recruitment-gerelateerde blogs.",
  Image: "/seo-image-general.png",
}

export default () => {
  useEffect(() => {
    navigate("/blog")
  }, [])

  return (
    <SEO
      title={blogMetadata.Title}
      description={blogMetadata.Description}
      image={blogMetadata.Image}
      keywords={blogMetadata.Keywords}
    />
  )
}
